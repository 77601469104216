<template>
  <section id="final-users">
    <b-row class="mb-5">
      <b-col
        md="9"
        class="pb-3 pb-md-0"
      >
        <h1>{{ $t("administrators.title") }}</h1>
        <h5 class="text-primary">
          {{ $t("administrators.subtitle") }}
        </h5>
      </b-col>
      <b-col
        md="3"
        class="text-right"
        align-self="end"
      >
        <b-link
          :to="{
            name: 'administrators-wizard'
          }"
        >
          <b-button variant="primary">
            <span class="text-nowrap">{{ $t("administrators.add") }}</span>
          </b-button>
        </b-link>
      </b-col>
    </b-row>
    <b-card>
      <table-header
        :total="totalUsers"
        :per-page.sync="perPage"
        :search-query.sync="searchQuery"
        :call-method="'allProfiles'"
        @export="exportTable"
      />
      <b-table
        ref="refUserListTable"
        :items="fetchedUsers"
        :fields="tableColumns"
        :sort-by.sync="sortBy"
        :sort-desc.sync="isSortDirDesc"
        show-empty
        :busy="isBusy"
        :empty-text="$t('emptyText')"
        responsive
        primary-key="id"
        @sort-changed="onSortChanged"
      >
        <!-- Column: User -->
        <template #table-busy>
          <div
            v-if="!exporting"
            class="text-center text-danger my-2"
          >
            <b-spinner class="align-middle mr-1" />
            <strong>{{ $t('load') }}...</strong>
          </div>
          <div
            v-else
            class="text-center my-2"
          >
            <div class="text-center">
              <feather-icon
                icon="ClockIcon"
                size="30"
              />
              <p id="cancel-label">
                {{ $t('exporting') }}
              </p>
              <ICountUp
                :delay="delay"
                :end-val="nExport"
                :options="options"
              />
              {{ $t('of') }}
              <ICountUp
                :delay="delay"
                :end-val="totalCount"
                :options="options"
              />
              {{ $t('usuarios') }}
            </div>
          </div>
        </template>
        <template #cell(username)="data">
          <b-media
            v-if="!forbid"
            vertical-align="center"
          >
            <template #aside>
              <b-avatar
                size="32"
                :text="avatarText(data.item.node.firstName)"
                variant="light-success"
                :to="{
                  name: 'administrators-setting',
                  params: { id: data.item.node.id }
                }"
              />
            </template>
            <b-link
              :to="{
                name: 'administrators-setting',
                params: { id: data.item.node.id }
              }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.node.firstName }} {{ data.item.node.lastName }}
            </b-link>
            <b-link
              :to="{
                name: 'administrators-setting',
                params: { id: data.item.node.id }
              }"
              class="font-weight-bold d-block text-nowrap"
            >
              <small class="text-muted">@{{ data.item.node.username }}</small>
            </b-link>
          </b-media>
          <b-media
            v-else
            vertical-align="center"
          >
            <template #aside>
              <b-avatar
                size="32"
                :text="avatarText(data.item.node.firstName)"
                variant="light-success"
              />
            </template>
            <span class="font-weight-bold d-block text-nowrap">
              {{ data.item.node.firstName }} {{ data.item.node.lastName }}
            </span>
            <span class="font-weight-bold d-block text-nowrap">
              <small class="text-muted">@{{ data.item.node.username }}</small>
            </span>
          </b-media>
        </template>

        <!-- Column: Email -->
        <template #cell(email)="data">
          <div class="text-nowrap">
            <feather-icon
              icon="MailIcon"
              size="18"
              class="mr-50"
            />
            <span class="align-text-top">{{ data.item.node.email }}</span>
          </div>
        </template>
        <!-- Column: ROL -->
        <template #cell(groups)="data">
          <div>
            <span class="align-text-top">{{
              data.item.node.customuserPtr.groups.edges.map((group) => group.node.name).join(', ')

            }}</span>
          </div>
        </template>

        <!-- Column: Status -->
        <template #cell(isActive)="data">
          <b-badge
            pill
            :variant="`light-${resolveUserStatusVariant(data.item.node.isActive)}`
            "
            class="text-capitalize"
          >
            <feather-icon
              :icon="data.item.node.isActive ? 'CheckIcon' : 'XIcon'"
              size="18"
              class="mr-50"
            />
            {{
              data.item.node.isActive
                ? $t("dataGeneric.active")
                : $t("dataGeneric.inactive")

            }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div
            v-if="!forbid"
            class="float-right"
          >
            <b-link
              class="ml-1"
              :to="{
                name: 'administrators-setting',
                params: { id: data.item.node.id }
              }"
            >
              <feather-icon
                icon="EditIcon"
                size="17"
                class="text-warning"
              />
            </b-link>
            <b-link
              class="ml-1"
              @click="confirmText(data.item.node.id)"
            >
              <feather-icon
                icon="XCircleIcon"
                size="17"
                class="text-danger"
              />
            </b-link>
          </div>
        </template>
      </b-table>
      <table-footer
        v-model="currentPage"
        :from="dataMeta.from"
        :to="dataMeta.to"
        :total="totalUsers"
        :per-page="perPage"
      />
    </b-card>
  </section>
</template>

<script>
import * as constants from '@core/utils/constants'
import {
  BTable,
  BRow,
  BCol,
  BCard,
  BLink,
  BMedia,
  BAvatar,
  BSpinner,
  BBadge,
  BButton,
} from 'bootstrap-vue'
import axios from '@axios'
import {
  messageError, isEmpty, showToast, checkPermissions,
} from '@/store/functions'
import xlsExport from 'xlsexport'
import { getUserData } from '@/auth/utils'
import ICountUp from 'vue-countup-v2'
import { avatarText } from '@core/utils/filter'
import TableFooter from '../../components/table/TableFooter.vue'
import TableHeader from '../../components/table/TableHeaderPers.vue'

export default {
  components: {
    BTable,
    BRow,
    BCol,
    BCard,
    BLink,
    BMedia,
    ICountUp,
    BSpinner,
    BAvatar,
    BBadge,
    BButton,

    // My components
    TableFooter,
    TableHeader,
  },
  data() {
    return {
      USER_APP_STORE_MODULE_NAME: 'admin-user-list',
      userData: getUserData(),
      isEmpty,
      forbid: false,
      constants,
      options: {
        useEasing: true,
        useGrouping: true,
        separator: ',',
        decimal: '.',
        prefix: '',
        suffix: '',
      },
      dataToExport: [],
      isBusy: false,
      exporting: false,
      nExport: 0,
      totalCount: 0,
      delay: 1000,
      isAddNewUserSidebarActive: false,
      searchQuery: '',

      // Table Handlers
      tableColumns: [
        { key: 'username', sortable: true, label: this.$t('administrators.user') },
        { key: 'email', sortable: true },
        { key: 'groups', sortable: true, label: this.$t('Rol') },
        { key: 'isActive', sortable: true, label: this.$t('code.status') },
        { key: 'actions', label: this.$t('resources.tableHeader.actions') },
      ],
      perPage: 10,
      totalUsers: 0,
      currentPage: 1,
      perPageOptions: [10, 25, 50, 100],
      sortBy: 'id',
      isSortDirDesc: true,
      statusFilter: null,
      fetchedUsers: [],
    }
  },
  computed: {
    dataMeta() {
      const localItemsCount = this.$refs.refUserListTable ? this.$refs.refUserListTable.localItems.length : 0
      return {
        from: this.perPage * (this.currentPage - 1) + (localItemsCount ? 1 : 0),
        to: this.perPage * (this.currentPage - 1) + localItemsCount,
        of: this.totalUsers,
      }
    },
  },
  watch: {
    searchQuery() {
      this.fetchData()
    },
    perPage() {
      this.fetchData()
    },
    currentPage() {
      this.fetchData()
    },
  },
  mounted() {
    if (!this.userData.isSuperuser) this.forbid = !checkPermissions('users.change_profile')
    this.fetchData()
  },
  methods: {
    avatarText,
    fetchData() {
      const data = {
        q: this.searchQuery,
        perPage: this.perPage,
        page: this.currentPage,
        sortBy: this.sortBy,
        sortDesc: this.isSortDirDesc,
        status: this.statusFilter,
      }
      this.fetchUsers(data)
        .then(response => {
          const { edges, totalCount } = response.data.data.allProfiles
          this.fetchedUsers = edges
          this.totalUsers = totalCount
        })
        .catch(() => {
          showToast(this.$t('error'), 2, this)
        })
    },
    fetchUsers(queryParams) {
      const userData = getUserData()

      return new Promise((resolve, reject) => {
        axios
          .post('', {
            query: `
              {
                allProfiles(
                    first: ${queryParams.perPage},
                    offset: ${queryParams.perPage * (queryParams.page - 1)},
                    username: "${queryParams.q}",
                    client:"${userData.profile.client.id}",
                    #excludeName:${!userData.isSuperuser},
                    orderBy: "${queryParams.sortDesc ? '-' : ''}${queryParams.sortBy}",
                    ${queryParams.status !== null ? 'isActive: ' : ''}${queryParams.status !== null ? queryParams.status.toLowerCase() : ''}
                  ) {
                  totalCount
                  edgeCount
                  edges {
                    node {
                      id
                      username
                      firstName
                      lastName
                      email
                      isActive
                      profileVendor {
                        edges {
                          node {
                            id
                            name
                          }
                        }
                      }
                      avatar
                      description
                      subgroup {
                        id
                        name
                      }
                      customuserPtr {
                        groups {
                          edges {
                            node {
                              id
                              name
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            `,
          })
          .then(response => {
            const allProfiles = response
            const searchName = response.data.data.allProfiles.edges

            axios
              .post('', {
                // params: queryParams
                query: `
                  {
                    allProfiles(
                        first: ${queryParams.perPage},
                        offset: ${queryParams.perPage * (queryParams.page - 1)},
                        email: "${queryParams.q}",
                        client:"${userData.profile.client.id}",
                        #excludeName:${!userData.isSuperuser},
                        orderBy: "${queryParams.sortDesc ? '-' : ''}${queryParams.sortBy}",
                        ${queryParams.status !== null ? 'isActive: ' : ''}${queryParams.status !== null ? queryParams.status.toLowerCase() : ''}
                      ) {
                      totalCount
                      edgeCount
                      edges {
                        node {
                          id
                          username
                          firstName
                          lastName
                          email
                          isActive
                          profileVendor {
                            edges {
                              node {
                                id
                                name
                              }
                            }
                          }
                          avatar
                          description
                          subgroup {
                            id
                            name
                          }
                          customuserPtr {
                            groups {
                              edges {
                                node {
                                  id
                                  name

                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                `,
              }).then(result => {
                const searchMail = result.data.data.allProfiles.edges
                let nuevo = [...searchName, ...searchMail]

                const hash = {}
                nuevo = nuevo.filter(current => {
                  const exists = !hash[current.node.id]
                  hash[current.node.id] = true
                  return exists
                })

                allProfiles.data.data.allProfiles.edges = nuevo

                resolve(allProfiles)
              }).catch(err => {
                reject(err)
              })
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    deleteUser(id) {
      this.deleteUserMutation(id)
        .then(response => {
          const { found, deletedId } = response.data.data.deleteProfile
          if (found && deletedId) {
            this.fetchData()
            showToast(this.$t('success'), 1, this)
          }
        })
        .catch(() => {
          showToast(this.$t('error'), 2, this)
        })
    },
    deleteUserMutation(id) {
      return new Promise((resolve, reject) => {
        axios
          .post('', {
            query: `
              mutation{
                deleteProfile(id: "${id}") {
                  found
                  deletedId
                }
              }
            `,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    resolveUserStatusVariant(status) {
      if (status === true) return 'success'
      if (status === false) return 'secondary'
      return 'secondary'
    },
    confirmText(id) {
      this.$swal({
        title: this.$t('code.title'),
        text: this.$t('code.text'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('code.confirm'),
        cancelButtonText: this.$t('dataGeneric.cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.deleteUser(id)
        }
      })
    },
    exportTable(data) {
      this.dataToExport = []
      this.exporting = !this.exporting
      this.isBusy = !this.isBusy
      this.nExport = 0
      const dates = []
      let typeExport = ''
      if (Array.isArray(data)) {
        const [type, startDate, endDate] = data
        typeExport = type
        dates.push(startDate)
        dates.push(endDate)
      } else {
        typeExport = data
      }
      this.exportData('', typeExport, dates)
    },
    formatDate(dateString) {
      const date = new Date(dateString)
      const day = String(date.getDate()).padStart(2, '0')
      const month = String(date.getMonth() + 1).padStart(2, '0')
      const year = date.getFullYear()
      const zeroWidthSpace = '\u200C'
      return `${zeroWidthSpace}${day}/${month}/${year}`
    },
    exportData(cursor = '', typeExport, dates = []) {
      const query = `{
        allProfiles (client:"${this.userData.profile.client.id}",
            after:"${cursor}",
          ${!isEmpty(dates) ? `startDate: "${dates[0]}", endDate: "${dates[1]}"` : ''}

            ){
                pageInfo {
                endCursor
                hasNextPage
              }
              totalCount
              edges {
                node {
                id
                username
                email
                firstName
                lastName
                isActive
                dateJoined
                customuserPtr {
                  groups {
                    edges {
                      node {
                        id
                        name

                      }
                    }
                  }
                }

                }
              }
            }
          }`
      axios
        .post('', {
          query,
        })
        .then(response => {
          messageError(response, this)
          const info = response.data.data.allProfiles
          const page = info.pageInfo
          const { edges, totalCount } = info
          const nodes = edges.map(item => item.node)
          this.dataToExport = [...this.dataToExport, ...nodes]
          this.nExport = this.dataToExport.length
          this.totalCount = totalCount
          if (page.hasNextPage) {
            this.exportData(page.endCursor, typeExport, dates)
          } else {
            const dataTable = this.dataToExport.map(item => {
              const rol = item.customuserPtr.groups.edges.map(group => group.node.name).join(', ')
              return {
                [this.$t('User')]: item.username,
                [this.$t('administrators.name')]: `${item.firstName} ${item.lastName}`,
                [this.$t('administrators.Permission')]: rol,
                [this.$t('Email')]: item.email,
                [this.$t('code.status')]: item.isActive ? this.$t('dataGeneric.active') : this.$t('dataGeneric.inactive'),
                [this.$t('dateRegistration')]: this.formatDate(item.dateJoined),
              }
            })
            const f = new Date()
            const m = f.getMonth() + 1
            const name = `${`${this.$t('Administrators')}_${f.getFullYear()}${m < 10 ? (`0${m}`) : `${m}`}`}${f.getDate()}`

            // eslint-disable-next-line new-cap
            const xls = new xlsExport(dataTable, this.$t('Users'))
            if (typeExport === 'XLS') xls.exportToXLS(`${name}.xls`)
            else xls.exportToCSV(`${name}.csv`)

            this.exporting = !this.exporting
            this.isBusy = !this.isBusy
          }
        }).catch(err => {
          console.log(err)
          showToast(this.$t('subtitles.translationError'), 0, this)
        })
    },
    onSortChanged(sortInfo) {
      this.orderBy = sortInfo.sortDesc ? sortInfo.sortBy : `-${sortInfo.sortBy}`
      this.fetchData()
    },
  },
}
</script>
